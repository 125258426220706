import React, { useEffect } from 'react'
import { Modal, Spinner } from '@telegram-apps/telegram-ui'
import DiscoverHeader from './DiscoverHeader'
import DiscoverItem from './DiscoverItem'
import TopUp from '../TopUp/TopUp'
import { useDiscoverStore } from './useDiscoverStore'
import { InferenceModel } from '../../api/types'

const Discover: React.FC = () => {
  const {
    isInitialDataReady,
    models,
    profile,
    modelsError,
    profileError,
    isTopUpModalOpen,
    fetchInitialData,
    openTopUpModal,
    closeTopUpModal,
    handleTopUpComplete
  } = useDiscoverStore()

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const renderContent = () => {
    if (!isInitialDataReady) {
      return (
        <div className='container' style={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Spinner size='l'/>
        </div>
      )
    }

    if (modelsError) {
      return (
        <div className="tgui-text-error">
          Error: {modelsError.message}
          {modelsError.status && <div>Status: {modelsError.status}</div>}
        </div>
      )
    }

    if (profileError) {
      return (
        <div className="tgui-text-error">
          Error: {profileError.message}
          {profileError.status && <div>Status: {profileError.status}</div>}
        </div>
      )
    }

    return (
      <>
        <DiscoverHeader 
          balance={profile?.balance || 0}
          onTopUp={openTopUpModal}
        />
        {models && models.map((model: InferenceModel) => (
          <DiscoverItem 
            key={model.id} 
            style={model} 
          />
        ))}
      </>
    )
  }

  return (
    <div className='container enable-scrollbar'>
      <Modal 
          open={isTopUpModalOpen}
          onOpenChange={closeTopUpModal}
          style={{backgroundColor: 'var(--tgui--secondary_bg_color)'}}
        >
          <TopUp balance={profile?.balance ?? 0} onClose={handleTopUpComplete} />
        </Modal>

        {renderContent()}
    </div>
  )
}

export default Discover