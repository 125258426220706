import useStore from './useStore'
import { getComponentForPath } from '../routes'

export const useNavigation = () => {
    const { pushLocation, popLocation, replaceLocation } = useStore()
    const activeTab = useStore((state) => state.activeTab)
    const currentLocation = useStore(
      (state) => state.locations[activeTab]?.[state.locations[activeTab].length - 1]
    )
  
    return {
      push: (pathname: string, props?: any) => {
        pushLocation(activeTab, {
          id: pathname,
          component: getComponentForPath(pathname),
          props,
          location: { pathname, state: props }
        })
      },
      replace: (pathname: string, props?: any) => {
        replaceLocation(activeTab, {
          id: pathname,
          component: getComponentForPath(pathname),
          props,
          location: { pathname, state: props }
        })
      },
      pop: () => popLocation(activeTab),
      currentLocation
    }
  }