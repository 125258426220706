import { create } from 'zustand'
import { AxiosError } from 'axios'
import { getTasks } from '../../api/api'
import { Task } from '../../api/types'
import { useNavigation } from '../../hooks/useLocation'
import useStore from '../../hooks/useStore'
import { TabType } from '../../types/tabs'

type FetchError = {
  message: string
  code?: string
  status?: number
}

interface ProfileState {
  tasks: Task[]
  isInitialLoading: boolean
  isRefetching: boolean
  error: FetchError | null
  
  isTaskModalOpen: boolean
  
  fetchTasks: () => Promise<void>
  addTask: (task: Task) => void
}

export const useProfileStore = create<ProfileState>((set, get) => ({
  tasks: [],
  isInitialLoading: true,
  isRefetching: false,
  error: null,
  isTaskModalOpen: false,
  
  fetchTasks: async () => {
    const hasExistingData = get().tasks.filter(task => !task.manuallyAdded).length > 0

    set(state => ({
      ...state,
      isInitialLoading: !hasExistingData,
      isRefetching: hasExistingData,
      error: null
    }))

    try {
      const response = await getTasks()
      set(state => ({ 
        ...state,
        tasks: response.data.tasks,
        isInitialLoading: false,
        isRefetching: false
      }))
    } catch (err) {
      const error: FetchError = {
        message: err instanceof AxiosError 
          ? err.response?.data?.message || err.message
          : 'Failed to fetch tasks',
        code: err instanceof AxiosError ? err.code : undefined,
        status: err instanceof AxiosError ? err.response?.status : undefined
      }
      set(state => ({ 
        ...state,
        error,
        isInitialLoading: false,
        isRefetching: false
      }))
    }
  },
  
  addTask: (task: Task) => {
    set(state => ({
      ...state,
      tasks: [task, ...state.tasks]
    }))
  }
  
}))

export const useProfileActions = () => {
  const navigation = useNavigation()
  const { setActiveTab } = useStore()
  const store = useProfileStore()

  return {
    ...store,
    openViewer: (index: number, model: Task) => navigation.push('/profile/result', { images: model.result, initialIndex: index, onClose: () => {navigation.pop()} }),
    redirectToDiscover: () => setActiveTab(TabType.DISCOVER),
    addTask: store.addTask
  }
}