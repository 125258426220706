import { createRoot } from 'react-dom/client';
import App from './App';

import './index.css';
import '@telegram-apps/telegram-ui/dist/styles.css';

import WebApp from '@twa-dev/sdk'

WebApp.ready();
WebApp.expand();
WebApp.setHeaderColor('#000000');


const root = createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
