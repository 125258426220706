import { create } from 'zustand'
import { AxiosError } from 'axios'
import { InferenceModel, User } from '../../api/types'
import { fetchModels, fetchProfile } from '../../api/api'

type FetchError = {
  message: string
  code?: string
  status?: number
}

interface DiscoverState {
  // Models state
  models: InferenceModel[]
  isModelsLoading: boolean
  isModelsRefetching: boolean
  modelsError: FetchError | null
  
  // Profile state
  profile: User | null
  isProfileLoading: boolean
  isProfileRefetching: boolean
  profileError: FetchError | null
  
  // Combined loading state
  isInitialDataReady: boolean
  
  // Modal state
  isTopUpModalOpen: boolean
  
  // Actions
  fetchModels: () => Promise<void>
  fetchProfile: () => Promise<void>
  fetchInitialData: () => Promise<void>
  openTopUpModal: () => void
  closeTopUpModal: (isOpen: boolean) => void
  handleTopUpComplete: (isSuccessPurchase: boolean) => void
}

const createFetchError = (err: unknown): FetchError => ({
  message: err instanceof AxiosError 
    ? err.response?.data?.message || err.message
    : 'An error occurred',
  code: err instanceof AxiosError ? err.code : undefined,
  status: err instanceof AxiosError ? err.response?.status : undefined
})

export const useDiscoverStore = create<DiscoverState>((set, get) => ({
  // Models state
  models: [],
  isModelsLoading: true,
  isModelsRefetching: false,
  modelsError: null,
  
  // Profile state
  profile: null,
  isProfileLoading: true,
  isProfileRefetching: false,
  profileError: null,
  
  // Combined loading state
  isInitialDataReady: false,
  
  // Modal state
  isTopUpModalOpen: false,
  
  fetchModels: async () => {
    const hasExistingData = get().models.length > 0

    set(state => ({
      ...state,
      isModelsLoading: !hasExistingData,
      isModelsRefetching: hasExistingData,
      modelsError: null
    }))

    try {
      const response = await fetchModels()
      set(state => ({ 
        ...state,
        models: response.data.styles,
        isModelsLoading: false,
        isModelsRefetching: false,
        isInitialDataReady: !state.isProfileLoading && !state.profileError
      }))
    } catch (err) {
      set(state => ({ 
        ...state,
        modelsError: createFetchError(err),
        isModelsLoading: false,
        isModelsRefetching: false
      }))
    }
  },
  
  fetchProfile: async () => {
    const hasExistingData = get().profile !== null

    set(state => ({
      ...state,
      isProfileLoading: !hasExistingData,
      isProfileRefetching: hasExistingData,
      profileError: null
    }))

    try {
      const response = await fetchProfile()
      set(state => ({ 
        ...state,
        profile: response.data,
        isProfileLoading: false,
        isProfileRefetching: false,
        isInitialDataReady: !state.isModelsLoading && !state.modelsError
      }))
    } catch (err) {
      set(state => ({ 
        ...state,
        profileError: createFetchError(err),
        isProfileLoading: false,
        isProfileRefetching: false
      }))
    }
  },
  
  fetchInitialData: async () => {
    await Promise.all([
      get().fetchModels(),
      get().fetchProfile()
    ])
  },
  
  openTopUpModal: () => {
    set(state => ({ ...state, isTopUpModalOpen: true }))
  },
  
  closeTopUpModal: (isOpen: boolean) => {
    if (isOpen) return
    set(state => ({ ...state, isTopUpModalOpen: false }))
  },
  
  handleTopUpComplete: (isSuccessPurchase: boolean) => {
    set(state => ({ ...state, isTopUpModalOpen: false }))
    if (isSuccessPurchase) {
      get().fetchInitialData()
    }
  }
}))