import { AxiosResponse } from "axios";
import { api } from "./client";
import { User, InferenceResponse, MediaUploadResponse, InvoiceCreateResponse, Task } from "./types";

export const fetchProfile = async (): Promise<AxiosResponse<User>> => {
    const response = await api.get<User>('/profile');
    return response;
};

export const fetchModels = async (): Promise<AxiosResponse<InferenceResponse>> => {
    const response = await api.get<InferenceResponse>('/models');
    return response;
};

export const uploadFile = async (file: File, signal?: AbortSignal): Promise<AxiosResponse<MediaUploadResponse>> => {
    const formData = new FormData();
    formData.append('file', file);
  
    const response = await api.post<MediaUploadResponse>('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal,
    });

    return response;
};

export const postTasks = async (styleId: number, photoId: number, gender: string): Promise<AxiosResponse<Task>> => {
  const response = await api.post('/tasks', { style_id: styleId, photo_id: photoId, gender: gender });
  return response;
}

export const getTasks = async () => {
  const response = await api.get('/tasks');
  return response;
}

export const createInvoice = async (generations: number, stars: number): Promise<AxiosResponse<InvoiceCreateResponse>> => {
  const response = await api.post('/invoices', {title: 'Top up generations', description: `${generations} generations`, amount: stars});
  return response;
}