import {useEffect} from "react";
import {useProfileActions, useProfileStore} from "./useProfileStore";
import {Button, Headline, Text} from "@telegram-apps/telegram-ui";
import {Task} from "../../api/types";
import './Profile.css'
import CachedImage from "../../components/CachedImage/CachedImage";
import WebSocketService from "../../api/websocket";
import {IconAdd} from "../../icons/IconAdd";

const Profile = () => {
  const {
    tasks,
    error,
    isInitialLoading,
    fetchTasks
  } = useProfileStore();

  const {
    openViewer,
    redirectToDiscover
  } = useProfileActions();

  const handleOpenViewer = (index: number, model: Task) => {
    openViewer(index, model)
  }

  const initLoading = isInitialLoading && tasks.length === 0;
  const hasError = error && tasks.length === 0;

  useEffect(() => {
    const wsService = WebSocketService.getInstance();

    fetchTasks();

    const handleTaskStatus = (data: { task_id: number, status: string }) => {
      fetchTasks();
    };

    wsService.on('task_status', handleTaskStatus);

    return () => {
      wsService.off('task_status', handleTaskStatus);
    };
  }, [fetchTasks]);

  return (
    <div className='container enable-scrollbar'
         style={{padding: '0px', display: 'flex', flexDirection: 'column', gap: '16px'}}>
      <Button
        size='s'
        onClick={redirectToDiscover}
        before={<IconAdd/>}
        style={{height: '42px', margin: '16px 16px 0'}}>
        Generate More Photos
      </Button>


      {hasError && <div style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        padding: '0 16px',
        color: 'var(--tgui--destructive_text_color)'
      }}>
        <Headline weight="1" style={{color: 'inherit'}}>Error</Headline>
        {error.status && <Text weight="2" style={{color: 'inherit'}}>{error.status}</Text>}
        <Text weight="3" style={{color: 'inherit'}}>{error.message}</Text>
      </div>}

      {(!initLoading || tasks.length > 0) && !hasError && tasks && tasks.map((model: Task) => (
        <div key={model.id} className="profile-item">
          <Headline weight="2" style={{
            padding: '0 16px',
            display: 'inline-flex',
            alignItems: 'baseline',
            gap: 2
          }}>{model.style_name}{(model.status === 'running' || model.status === 'pending') &&
            <Text weight="3" style={{color: 'var(--tgui--hint_color)', marginLeft: '4px'}}> •
              Generating...</Text>}</Headline>
          {(model.status === 'running' || model.status === 'pending') &&
            <SkeletonImagesRow/>}
          {model.status === 'done' && <div className="carousel-container">
            {model.result && model.result.map((item: string, index: number) => (
              <CachedImage
                className="image-container"
                key={`${model.id}-${index}`}
                src={item}
                alt={`Image ${item}`}
                style={{width: '100%', height: '150px', borderRadius: '10px', objectFit: 'cover', cursor: 'pointer'}}
                onClick={() => handleOpenViewer(index, model)}
                threshold={20}
              />
            ))}
          </div>}
        </div>
      ))}

      {initLoading && (
        <div style={{display: 'flex', flexDirection: 'column', gap: '16px', flexGrow: 1, overflow: 'hidden'}}>
          {Array.from({length: 5}).map((_, itemIndex) => (
            <div key={itemIndex} className="profile-item">
              <div className="skeleton" style={{
                alignSelf: 'flex-start',
                margin: '2px 16px',
                width: '125px',
                height: '24px',
                borderRadius: '8px'
              }}></div>
              <SkeletonImagesRow/>
            </div>
          ))}
        </div>)}
    </div>
  )
}

const SkeletonImagesRow: React.FC = () => {
  return (<div className="carousel-container" style={{overflowX: 'hidden'}}>
    {Array.from({length: 5}).map((_, index) => (
      <Button
        key={index}
        mode="plain"
        className="image-container skeleton"
        style={{cursor: 'default'}}
      />
    ))}
  </div>);
}

export default Profile
