import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './CachedImage.css';

interface CachedImageProps {
  className?: string;
  src: string;
  alt: string;
  // width: number;
  // height: number;
  style: React.CSSProperties | undefined;
  forceNotLazy?: boolean;
  threshold?: number;
  onClick?: () => void;
}

const CachedImage = (props: CachedImageProps) => {
  return (
    <div className='cached-image-skeleton' style={{...props.style}}>
      <LazyLoadImage
        className={props.className}
        src={props.src}
        alt={props.alt}
        style={props.style}
        effect='opacity'
        loading={'lazy'}
        visibleByDefault={props.forceNotLazy}
        threshold={props.threshold}
        wrapperProps={{style: {display: 'inline-flex', width: '100%', height: '100%'}}}
        onClick={props.onClick}
      />
    </div>
  )
}

export default CachedImage;