import { lazy } from 'react'

export const DiscoverScreen = lazy(() => import('../screens/Discover/Discover'))
export const PickerScreen = lazy(() => import('../screens/Picker/Picker'))
export const UploadInstructionScreen = lazy(() => import('../screens/UploadInstruction/UploadInstruction'))
export const ResultImageViewer = lazy(() => import('../screens/ResultImageViewer/ResultImageViewer'))
export const ProfileScreen = lazy(() => import('../screens/Profile/Profile'))
export const StickersScreen = lazy(() => import('../screens/Stickers/Stickers'))
export const NotFoundScreen = lazy(() => import('../screens/NotFound'))

export const routes: Record<string, React.ComponentType<any>> = {
  '/discover': DiscoverScreen,
  '/discover/instruction/picker': PickerScreen,
  '/discover/instruction': UploadInstructionScreen,
  '/discover/instruction/picker/result': ResultImageViewer,
  '/stickers': StickersScreen,
  '/profile': ProfileScreen,
  '/profile/result': ResultImageViewer
}

export const getComponentForPath = (pathname: string) => {
  return routes[pathname] || NotFoundScreen
}