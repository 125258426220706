import React, { useState } from 'react'
import { Button, Title, Caption, Text } from '@telegram-apps/telegram-ui'
import { formatter } from '../../helpers/formatter';
import bolt from '../../icons/bolt.json';
import Lottie from 'lottie-react';
import {Icon24Close} from '@telegram-apps/telegram-ui/dist/icons/24/close';
import { ModalClose } from '@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose';
import IconBolt from '../../icons/IconBolt';
import iconStar from '../../icons/icon_star20.png';
import { createInvoice } from '../../api/api';
import WebApp from '@twa-dev/sdk';

interface TopUpOption {
    generations: number;
    stars: number;
}

interface TopUpProps {
    balance: number;
    onClose?: (isSuccessPurchase: boolean) => void;
}

const TopUp: React.FC<TopUpProps> = (props) => {
    const options: TopUpOption[] = [
        {generations: 10, stars: 30},
        {generations: 30, stars: 90},
        {generations: 60, stars: 180},
        {generations: 100, stars: 300},
        {generations: 500, stars: 1500}
    ];

    const [loadingOption, setLoadingOption] = useState<TopUpOption | null>(null);

    const handleOptionTap = (index: number) => {
        buy(options[index]);
    };

    const buy = async (option: TopUpOption) => {
        setLoadingOption(option);
        const invoice = await createInvoice(option.generations, option.stars);
        setLoadingOption(null);
        WebApp.openInvoice(invoice.data.invoice_link, (event: any) => {
            if (event === 'paid') {
                props.onClose?.(true);
            }
        });
    };

    return (
        <div>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                maxWidth: '100vw',
                padding: '16px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <div style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                    <Caption weight='3'>Balance</Caption>
                    <div style={{display: 'flex', gap: '2px', alignItems: 'center'}}>
                        <IconBolt width={12} height={18} />
                        <Text weight='1'>{formatter.format(props.balance)}</Text>
                    </div>
                </div>
                <ModalClose><Icon24Close /></ModalClose>
            </div>
            <div style={{
                paddingTop: '24px',
                paddingBottom: '24px',
                paddingLeft: '16px',
                paddingRight: '16px',
                display: 'flex',
                gap: '8px',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <div style={{display: 'flex', gap: '8px', flexDirection: 'column', alignItems: 'center'}}>
                    <Lottie animationData={bolt} loop={true} style={{width: '64px', height: '82px'}} />
                    <Title level='2' weight='1'>Top Up Generations</Title>
                </div>
                <div style={{display: 'flex', gap: '16px', flexDirection: 'column', alignItems: 'center'}}>
                    {options.map((option, index) => (
                        <Button
                            key={index}
                            onClick={() => handleOptionTap(index)}
                            size='m'
                            mode={'filled'}
                            style={{backgroundColor: 'var(--tgui--bg_color)'}}
                            loading={loadingOption === option}
                        >
                            <div
                                style={{
                                    width: 'calc(100vw - 16px - 16px - 16px - 16px)',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                    <IconBolt width={17} height={24} />
                                    <Title weight='1' style={{color: 'var(--tgui--text_color)'}}>{option.generations}</Title>
                                </div>
                                <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                    <Text weight='3' style={{color: 'var(--tgui--text_color)'}}>{option.stars}</Text>
                                    <img alt="star" src={iconStar}></img>
                                </div>
                            </div>
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TopUp;
