import React, {useEffect} from 'react';
import {Tabbar} from '@telegram-apps/telegram-ui';
import { TabType } from '../../types/tabs'
import useStore from '../../hooks/useStore'
import WebApp from '@twa-dev/sdk';
import IconGame28 from '../../icons/IconGame28';
import IconSmile28 from '../../icons/IconSmile28';
import IconGallery28 from '../../icons/IconGallery28';

const TabBar: React.FC = () => {
  const { activeTab, setActiveTab } = useStore()

  const tabs = [
    { id: TabType.DISCOVER, label: 'Avatars' },
    { id: TabType.STICKERS, label: 'Stickers' },
    { id: TabType.PROFILE, label: 'Gallery' }
  ]

  const updateTabBarHeight = () => {
      const tabBarHeight = document.querySelector('#tabBar')?.clientHeight || 32;
      document.documentElement.style.setProperty('--tab-bar-height', `${tabBarHeight}px`);
  }

  const overscrollPreventListener = () => {
      if (window.scrollY > 0) window.scrollTo(0, 0);
  }

  useEffect(() => {
      window.addEventListener('scroll', overscrollPreventListener);
      window.addEventListener('resize', updateTabBarHeight);

      WebApp.onEvent('viewportChanged', updateTabBarHeight);
      setTimeout(updateTabBarHeight, 1000);

      return () => {
          window.removeEventListener('scroll', overscrollPreventListener);
          window.removeEventListener('resize', updateTabBarHeight);
      };
  }, []);

  return (
      <Tabbar id={'tabBar'} style={{paddingBottom: 'var(--safe-area-inset-bottom)'}}>
          {tabs.map((tab) =>
              <Tabbar.Item
                  key={tab.id}
                  text={tab.label}
                  selected={tab.id === activeTab}
                  onClick={() => setActiveTab(tab.id)}
              > 
                {tab.id === TabType.DISCOVER && <IconGame28/>}
                {tab.id === TabType.STICKERS && <IconSmile28/>}
                {tab.id === TabType.PROFILE && <IconGallery28/>}
              </Tabbar.Item>)}
      </Tabbar>
  );
};

export default TabBar;