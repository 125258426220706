import React from 'react'
import { Title, Caption, Button } from '@telegram-apps/telegram-ui';
import "./DiscoverHeader.css";
import IconBolt from '../../icons/IconBolt';
import { formatter } from '../../helpers/formatter';

interface DiscoverHeaderProps {
  balance: number;
  onTopUp?: () => void;
}

const DiscoverHeader: React.FC<DiscoverHeaderProps> = (props) => {
  return (
    <div className="leaderboard-top-item">
      <div className="leaderboard-top-item__top">
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Caption weight='3' style={{color: 'var(--tgui--hint_color)', lineHeight: '16px'}}>Balance</Caption>
            <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
              <IconBolt />
              <Title weight='1'>{formatter.format(props.balance)}</Title>
            </div>
          </div>
          <Button size='s' onClick={props.onTopUp}>Top Up</Button>
        </div>
      </div>
    </div>
  )
}

export default DiscoverHeader
