import { EventEmitter } from 'eventemitter3';

class WebSocketService {
  private static instance: WebSocketService;
  private socket!: WebSocket;
  private eventEmitter: EventEmitter;
  private url!: string;
  private isManuallyClosed: boolean = false;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 15;
  private reconnectDelay: number = 2000;

  private constructor() {
    this.eventEmitter = new EventEmitter();
  }

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  public connect(url: string): void {
    if (this.socket) {
      console.warn('WebSocket is already connected or connecting.');
      return;
    }

    this.url = url;
    this.isManuallyClosed = false;

    this.initializeWebSocket();
  }

  public isConnected(): boolean {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  private initializeWebSocket(): void {
    console.log('Attempting to connect to WebSocket:', this.url);

    try {
        this.socket = new WebSocket(this.url);
    } catch (error) {
        console.error('Failed to create WebSocket instance:', error);
        return;
    }

    this.socket.onopen = () => {
        console.log('WebSocket connection established to:', this.url);
        this.eventEmitter.emit('connected');
    };

    this.socket.onmessage = (event) => {
        console.log('WebSocket message received:', event.data);
        const message = JSON.parse(event.data);
        if (message.type) {
        this.eventEmitter.emit(message.type, message.data);
        }
    };

    this.socket.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
        this.eventEmitter.emit('disconnected');
        if (!this.isManuallyClosed) {
        this.reconnect(); // Attempt to reconnect
        }
    };

    this.socket.onerror = (error) => {
        console.error('WebSocket error encountered:', error);
        this.eventEmitter.emit('error', error);
    };
  }

  private reconnect(): void {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.error('Max reconnect attempts reached. Giving up.');
      this.eventEmitter.emit('reconnect_failed');
      return;
    }

    this.reconnectAttempts += 1;
    console.log(`Reconnecting... Attempt ${this.reconnectAttempts}`);

    setTimeout(() => {
      this.initializeWebSocket();
    }, this.reconnectDelay);
  }

  public close(): void {
    this.isManuallyClosed = true;
    if (this.socket) {
      this.socket.close();
      this.socket = undefined!;
    }
  }

  public on(event: string, listener: (...args: any[]) => void): void {
    this.eventEmitter.on(event, listener);
  }

  public off(event: string, listener: (...args: any[]) => void): void {
    this.eventEmitter.off(event, listener);
  }

  public send(event: string, data: any): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ event, data }));
    } else {
      console.warn('WebSocket is not open. Unable to send message.');
    }
  }
}

export default WebSocketService;
