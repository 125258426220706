import React from 'react'
import {Button, Title} from '@telegram-apps/telegram-ui';
import { useNavigation } from '../../hooks/useLocation'
import { InferenceModel } from '../../api/types';
import CachedImage from '../../components/CachedImage/CachedImage';
import IconBolt from "../../icons/IconBolt";

interface DiscoverItemProps {
  style: InferenceModel;
}

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  card: {
    flexShrink: 0,
    width: '100%',
    height: '240px',
    borderRadius: '16px',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    marginBottom: 8
  },
  imageGrid: {
    boxSizing: 'border-box',

    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '150px',
    height: 'calc(150px + 20px + 24px)',
    gap: '8px',

    padding: '20px 20px 24px'
  },
  image: {
    width: '100%',
    height: '150px',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    left: '20px'
  },
  button: {
    backgroundColor: 'white',
    color: '#8B5CF6',
    border: 'none',
    padding: '12px 24px',
    borderRadius: '8px',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

const DiscoverItem: React.FC<DiscoverItemProps> = (props) => {
  const navigation = useNavigation()
  const images = [props.style.example_1, props.style.example_2, props.style.example_3];

  const handleItemClick = () => {
    navigation.push('/discover/instruction', { styleId: props.style.id });
  }

  return (
    <div style={{...styles.card, background: `linear-gradient(180deg, #${props.style.gradient_from}, #${props.style.gradient_to})`}}>
      <div style={styles.imageGrid}>
        {images?.map((src: string, index: number) => (
          <CachedImage
            key={`image-${index}`}
            src={src}
            alt={`Card image ${index + 1}`}
            style={styles.image}
          />
        ))}
      </div>
      <div style={styles.buttonContainer}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <Title level='2' weight='1' style={{color: 'white'}}>{props.style.name}</Title>
          <Button
            size={'s'}
            onClick={handleItemClick}
          ><div style={{display: 'flex', alignItems: 'center', gap: 4}}>
            {props.style.price}<IconBolt width={14.17} height={20} />
          </div></Button>
        </div>
      </div>
    </div>
  )
}

export default DiscoverItem
