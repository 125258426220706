import React, { useEffect } from 'react'
import TabBar from './components/TabBar/TabBar'
import Discover from './screens/Discover/Discover'
import Profile from './screens/Profile/Profile'
import Stickers from './screens/Stickers/Stickers'
import NavigationStack from './components/NavigationStack/NavigationStack'
import { TabType } from './types/tabs'
import { SDKProvider } from "@telegram-apps/sdk-react";
import useStore from './hooks/useStore'
import { AppRoot } from '@telegram-apps/telegram-ui'
import WebSocketService from './api/websocket'
import WebApp from '@twa-dev/sdk'

const App: React.FC = () => {
  

  const { activeTab, isTabbarVisible, setActiveTab } = useStore()

  useEffect(() => {
    const wsService = WebSocketService.getInstance();

    if (!wsService.isConnected()) {
      wsService.connect(`https://ws.stickersgen.online/ws?${WebApp.initData}`);
    }

    const handleTaskUpdate = (data: {task_id: number, status: string}) => {
      if (data.status === 'done' && activeTab !== TabType.PROFILE) {
        setActiveTab(TabType.PROFILE);
      }
    }

    wsService.on('task_status', handleTaskUpdate);

    return () => {
      wsService.off('task_status', handleTaskUpdate);
    }
  }, [activeTab, setActiveTab]);

  const renderContent = () => {
    switch (activeTab) {
      case TabType.DISCOVER:
        return <NavigationStack tab={TabType.DISCOVER} rootComponent={Discover}/>
      case TabType.STICKERS:
        return <NavigationStack tab={TabType.STICKERS} rootComponent={Stickers}/>
      case TabType.PROFILE:
        return <NavigationStack tab={TabType.PROFILE} rootComponent={Profile}/>
      default:
        return null
    }
  }

  return (
    <SDKProvider>
      <AppRoot>
        {renderContent()}
        {isTabbarVisible && <TabBar />}
      </AppRoot>
    </SDKProvider>
  )
}

export default App