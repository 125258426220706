import React, { useEffect, useCallback, Suspense } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import useStore from '../../hooks/useStore'
import { useNavigation } from '../../hooks/useLocation'
import { TabType } from '../../types/tabs'
import { useBackButton } from '@telegram-apps/sdk-react'

const forceHighPerformance = () => {
  // Request high frame rate displays if available
  if ('screen' in window && 'requestAnimationFrame' in window) {
    const screen = window.screen as any;
    if (screen.requestAnimationFrame) {
      screen.requestAnimationFrame({
        preferredFrameRate: 120,
      }).catch(() => {
        // Fallback silently - device may not support high refresh rate
      });
    }
  }
}

const highPerformanceTransform = {
  willChange: 'transform',
  backfaceVisibility: 'hidden' as const,
  perspective: '1000px',
  translateZ: '0',
}

const iosTransition = {
  type: "tween",
  duration: 0.2,
  ease: [0.25, 0.1, 0.25, 1],
  transformer: 'gpu',
  useHardwareAcceleration: true
}

const screenVariants = {
  enter: {
    x: '100%',
    opacity: 0.3,
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: '-25%',
    opacity: 0.8,
  }
}

// Loading component for Suspense fallback
const ScreenLoader = () => (
  <div className="flex items-center justify-center h-full">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
  </div>
)

interface NavigationStackProps<T = any> {
  tab: TabType;
  rootComponent: React.ComponentType<T>;
  rootProps?: T;
}

const NavigationStack = <T,>({ 
  tab, 
  rootComponent: RootComponent,
  rootProps,
}: NavigationStackProps<T>) => {
  const { navigationStacks } = useStore()
  const navigation = useNavigation()
  const bb = useBackButton(true)
  const stack = navigationStacks[tab]

  useEffect(() => {
    forceHighPerformance()
  }, [])

  const screens = [
    { 
      id: 'root', 
      component: RootComponent, 
      props: rootProps,
      location: { pathname: '/' } 
    },
    ...stack
  ]

  const handleBack = useCallback(() => {
    navigation.pop();
  }, [navigation]);

  const backButtonHandler = useCallback(() => {
    bb?.hide();
    handleBack();
  }, [bb, handleBack]);

  useEffect(() => {
    const hasBackStack = stack.length > 0;

    if (hasBackStack) {
      bb?.on('click', backButtonHandler);
      bb?.show();
    } else {
      bb?.hide();
    }

    return () => {
      bb?.off('click', backButtonHandler);
    };
  }, [stack, backButtonHandler, bb]);

  const containerRef = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!containerRef.current) return
    
    const observer = new ResizeObserver(() => {
      if (containerRef.current) {
        containerRef.current.style.transform = 'translateZ(0)'
      }
    })
    
    observer.observe(containerRef.current)
    return () => observer.disconnect()
  }, [])

  return (
    <div ref={containerRef} style={highPerformanceTransform}>
      <AnimatePresence initial={false} mode="popLayout">
        {screens.map((screen, index) => {
          const Component = screen.component
          return (
            <motion.div
              key={screen.id}
              variants={screenVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={iosTransition}
              style={{
                backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                zIndex: index,
                position: 'absolute',
                maxHeight: '100vh',
                height: '100vh',
                width: '100%',
              }}
            >
              <div>
                <Suspense fallback={<ScreenLoader />}>
                  <Component {...(screen.props || {})} />
                </Suspense>
              </div>
            </motion.div>
          )
        })}
      </AnimatePresence>
    </div>
  )
}

export default NavigationStack