import { Title, Caption } from "@telegram-apps/telegram-ui"
import Lottie from "lottie-react"
import duck from "../../icons/stickers_duck.json"

const Stickers = () => {
  return (
    <div className="container">
      <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px'}}>
        <div style={{flex: 1}}></div>
          <Lottie animationData={duck} style={{width: '112px', height: '112px'}} />
          <Title level='1' weight='2' style={{textAlign: 'center'}}>
            {'Stickers creation'}
            <br />
            {'is coming soon'}
          </Title>
          <Caption level='1' weight='3' style={{color: 'var(--tgui--hint_color)', textAlign: 'center'}}>
            {'You will be able to create custom sticker packs with any'}
            <br />
            {'person via Stick. Stay tuned!'}
          </Caption>
        <div style={{flex: 1}}></div>
      </div>
    </div>
  )
}
  
export default Stickers