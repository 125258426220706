import React from "react";

const IconBolt: React.FC<{width?: number, height?: number}> = ({width = 17, height = 24}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3503_1875)">
                <path d="M0.705078 13.3219C0.705078 13.0869 0.805302 12.8484 1.00575 12.6065L10.8864 0.465617C11.08 0.230609 11.2873 0.0854573 11.5085 0.0301614C11.7366 -0.0251345 11.9405 -0.00439854 12.1202 0.0923693C12.2999 0.189137 12.4244 0.344657 12.4935 0.558929C12.5695 0.766288 12.5488 1.01858 12.4313 1.31579L9.22756 9.87975H15.3447C15.5866 9.87975 15.7836 9.95578 15.9356 10.1078C16.0946 10.253 16.1741 10.4396 16.1741 10.6677C16.1741 10.9096 16.0773 11.1516 15.8838 11.3935L6.00312 23.524C5.80958 23.7659 5.59877 23.9145 5.37067 23.9698C5.14949 24.0251 4.94904 24.0044 4.76933 23.9076C4.58962 23.8109 4.46174 23.6553 4.38571 23.4411C4.31659 23.2268 4.33733 22.9711 4.44792 22.6738L7.662 14.1099H1.53452C1.29951 14.1099 1.10252 14.0373 0.943542 13.8922C0.784566 13.7401 0.705078 13.55 0.705078 13.3219Z" fill="#FF9500"/>
            </g>
            <defs>
                <clipPath id="clip0_3503_1875">
                    <rect width="17" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconBolt;
